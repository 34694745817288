window.addEventListener('message', (e: MessageEvent) => {
	let data: { context?: string; src?: string; height?: string };

	try {
		data = JSON.parse(e.data);
	} catch (error) {
		return false;
	}

	if (data.context !== 'iframe.resize') {
		return false;
	}

	const iframes = document.querySelectorAll<HTMLIFrameElement>(`iframe[src="${data.src}"]`);

	if (iframes.length == 0) {
		return false;
	}

	for (var i = 0; i < iframes.length; i++) {
		iframes[i].height = data.height ?? iframes[i].height;
		iframes[i].style.minHeight = (data.height ?? iframes[i].height) + 'px';
	}
	
	return;
});